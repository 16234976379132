import React from 'react';
import FadeEffect from "../../components/FadeEffect";

const Team = () => {
    return (
        <div className='bg-pink'>
            <div className="container mt-big">
                <h2 className='mb-big'>CULTURE</h2>
                <div className="row">
                    <div className="col-lg-6">
                        <FadeEffect>
                            <div className="object-fit ar-16-9">
                                <img src="/img/banner_21.jpg" alt=""/>
                            </div>
                            <div className="mb-big">
                                <h4 className='mt-lg-3 mt-2'>Soft Skill</h4>
                                <h5 className='mt-lg-3 mt-2'>활발한 의사소통을 통해 공감대를 형성하고 부서 및 기능간 협력을 통해 시너지 효과를 극대화합니다.</h5>
                            </div>
                        </FadeEffect>
                        <FadeEffect>
                            <div className="object-fit ar-16-9">
                                <img src="/img/banner_22.jpg" alt=""/>
                            </div>
                            <div className="mb-big">
                                <h4 className='mt-lg-3 mt-2'>Global Standard / Clean Code</h4>
                                <h5 className='mt-lg-3 mt-2'>글로벌 코딩 표준을 준수하고 단순하게 만들어서 누구라도 쉽게 이해할수 있는 코드를 개발합니다.</h5>
                            </div>
                        </FadeEffect>
                    </div>
                    <div className="col-lg-6 mt-lg-260">
                        <FadeEffect>
                            <div className="object-fit ar-16-9">
                                <img src="/img/banner_9.jpg" alt=""/>
                            </div>
                            <div className="mb-big">
                                <h4 className='mt-lg-3 mt-2'>Challenge</h4>
                                <h5 className='mt-lg-3 mt-2'>늘 새로운 목표를 향하여, 실패를 두려워하지 않는 도전정신으로 새로운 기술에 대한 도전을 추구합니다.</h5>
                            </div>
                        </FadeEffect>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Team;
// 0.006439
// -0.498608
