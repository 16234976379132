import React from 'react';
import {Link} from "react-router-dom";

const SceneSuccess = () => {
    return (
        <div className='text-center'>
            <h1>Request Sent</h1>
            <Link to='/'>
                <button className="btn btn-dark">Go Back</button>
            </Link>
        </div>
    );
};

export default SceneSuccess;
