import React, { Fragment } from 'react';
import FadeEffect from "./FadeEffect";
import { Link } from "react-router-dom";
import { xStore } from "../utils/mobXstore";
import { localBaseURL } from "../utils/fetcher";

const OurBusiness = ({ scrollY }) => {
    return (
        <div className='position-relative overflow-hidden gutter-big py-big'>
            <div
                style={{ transform: `translateX(${scrollY / 8 - 125}vw) translateY(0px)` }}
                className='scrolled-text'
            >OUR
            </div>
            <div
                style={{ transform: `translateX(${-scrollY / 8 + 175}vw) translateY(400px)` }}
                className='scrolled-text'
            >BUSINESS
            </div>

            <div className='container'>
                <h2 className='mb-big'>
                    저희는
                    <br />
                    몽골, 우즈벡에 <span>거점을 둔</span>
                    <br />
                    IT 전문 개발사<span>입니다.</span>
                </h2>
                <div className="row">
                    {
                        xStore.info.sort((a, b) => b.order - a.order)
                            .filter((_, id) => id < 4)
                            .map((element, id) => (
                                <Fragment key={id}>
                                    <FadeEffect className={`col-lg-6 ${id % 2 === 1 ? 'mt124' : 'mb124'}`}>
                                        <Link to={`/business/${element.id}`} className='card-item'>
                                            <div className='object-fit ar-16-9'>
                                                <img src={`${localBaseURL}/public/upload/info/${element.image}`}
                                                    alt='' />
                                                <div className='gradient'>
                                                    <h3>See more</h3>
                                                </div>
                                            </div>
                                            <h4 className="mt-lg-3 mt-2">{element.title.replaceAll('<br/>', ' ')}</h4>
                                            <h5 className="mt-lg-3 mt-2">{element.subtitle}</h5>
                                        </Link>
                                    </FadeEffect>
                                </Fragment>
                            ))
                    }
                </div>
            </div>
        </div>
    );
};

export default OurBusiness;
