import React from 'react';
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import { observer } from "mobx-react-lite";
import FadeEffect from "../../components/FadeEffect";
import { xStore } from "../../utils/mobXstore";
import { localBaseURL } from "../../utils/fetcher";
import BreadCrumb from '../../components/BreadCrumb';

const TeamPage = () => {

    return (
        <>
            <TopBar />
            <div className="container my-big">
                <BreadCrumb page="Team" />
                <h2 className='mb-big'>Creative Team</h2>
                <div className="row">
                    {xStore.team.map((element, id) => (
                        <div key={id} className="col-xl-3 col-lg-4 col-6">
                            <FadeEffect>
                                <div className='card-item'>
                                    <div className="object-fit ar-1-1">
                                        <img
                                            src={id === (xStore.team.length) ? element.image : `${localBaseURL}/public/upload/team/${element.image}`}
                                            alt='' />
                                        <div className="gradient" />
                                    </div>
                                    <h4 className='my-lg-2 mb-1 text-center'>{element.name}</h4>
                                    <h5 className='mb-3 text-center'>{element.job_type}</h5>
                                </div>
                            </FadeEffect>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default observer(TeamPage);
