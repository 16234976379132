import React from 'react';
import "swiper/css/effect-fade";
import MainSlider from "./MainSlider";
import TopBar from "./TopBar";

const Header = () => {
    return (
        <div className='main'>
            <TopBar watcher={true}/>
            <MainSlider/>

            <div className="scroll-down">
                <p>Scroll down</p>
                <img src="/img/down.svg" alt=""/>
            </div>
        </div>
    );
};

export default Header;
