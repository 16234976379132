import React from "react";
import {useForm} from "react-hook-form";
import {fetcher} from "../utils/fetcher";
import {NotificationManager} from "react-notifications";

const ContactUs = () => {
    const {register, reset, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = (data) => {
        NotificationManager.success("당신의 이메일이 전송되었습니다");
        fetcher.post("/api/mail/send", data);
        reset();
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    type="text"
                    placeholder="Name"
                    {...register("name", {required: true})}
                />
                {errors.name && <p className='form-warning'>당신의 이름을 입력 해주세요</p>}
                <input
                    type="email"
                    placeholder="E-mail"
                    {...register("mail", {
                        required: true,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: " ",
                        },
                    })}
                />
                {errors.mail && <p className='form-warning'>유효한 이메일을 입력해주세요</p>}
                <input
                    type="text"
                    placeholder="Tel"
                    {...register("tel", {
                        required: true, pattern: {
                            value: /^[\d| |+|-]+$/i,
                            message: " ",
                        },
                    })}
                />
                {errors.tel && <p className='form-warning'>숫자만 입력해 주세요</p>}
                <textarea
                    placeholder="Message"
                    className="mt-lg-4"
                    rows="7"
                    {...register("message", {required: true})}
                />
                {errors.name && <p className='form-warning'>메시지를 입력해주세요</p>}
                <div className="footer-buttons d-flex justify-content-center align-items-center">
                    <button type="submit" className="mt-big">
                        <h3 className="text-white">SEND</h3>
                    </button>
                    <a
                        href="/소프트허브글로벌_회사소개서.pdf"
                        className="mt-big text-nowrap"
                        download
                    >
                        <h3 className="text-white">회사소개서</h3>
                    </a>
                </div>
            </form>
        </div>
    );
};

export default ContactUs;
