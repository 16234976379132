import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const FooterBottom = () => {
  return (
    <footer className="bg-dark py-1">
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-4 d-flex">
            <Link to="/" className="mb-3">
              <Swiper
                className="logo-slider"
                modules={[Autoplay]}
                slidesPerView={1}
                loop={true}
                autoplay={{ delay: 5000 }}
              >
                <SwiperSlide>
                  <img src="/img/softhublogo.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/img/softbrainslogo.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/img/mkitlogo.png" alt="" />
                </SwiperSlide>
              </Swiper>
            </Link>
          </div>
          <div className="col-md-8 col-12 text-md-end">
            <div className="footer-menus row">
              <div className="col-md-3 col-6">
                <Link to="/create">Project Request​</Link>
                <Link to="/contact" className="d-md-none">Contact</Link>
              </div>
              <div className="col-md-3 col-6">
                <Link to="/about">About Us​</Link>
                <Link to="/business">Business</Link>
              </div>
              <div className="col-md-3 col-6">
                <Link to="/news">News​</Link>
                <Link to="/team">Team</Link>
              </div>
              <div className="col-md-3 col-6">
                <Link to="/techInfo">Tech info​</Link>
                <Link to="/contact" className="d-md-block d-none">Contact</Link>
              </div>
            </div>

            <p className="text-white fw-normal">
              Copyright ⓒ 2023 SOFTHUB. All right reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterBottom;
