import React from 'react';

const data = [
    {
        title: '2021 ~ 2023년',
        image: '/img/tashkent.jpg',
        rows: [
            '이투스Word Master 서비스 유지보수 개발',
            '뤼이드Air Math 서비스 유지보수(AI 기반 온라인 학습 서비스)',
            '하이텍공장 자동화 시스템 아웃소싱, IoT',
            '피데스 어드바이저리CrediValue(공정가치 자동화 솔루션) 서비스 개발',
            '2022년 2월 소프트허브 글로벌(주) 설립',
            '칸디드 사업권 소프트허브 글로벌 양도',
            '2022년 3월 Soft Brains LLC(우즈벡 현지 법인) 설립',
        ]
    },
    {
        title: '2019 ~ 2020년',
        image: '/img/timeline2.jpg',
        rows: [
            '유니온북 학습용 웹플랫폼(유니월드) 개발',
            'LG CNS 데이터 표준화 웹 관리 시스템 개발',
            '현대자동차 그룹 DAAS(Desktop As a Service) 구축 프로젝트',
            '글로벌 쇼핑몰(SAZAXA.com) 구축 프로젝트',
            '한국 중소 개발사(OK토마토등 10개사) 소프트웨어 개발 아웃소싱 용역',
            '몽골 IT 산업 인력 양성 및 비즈니스 인큐베이팅 사업(KOICA) 수행',
            'MKiT LLC(몽골 현지법인) 설립',
        ]
    },
    {
        title: '2018년',
        image: '/img/timeline3.jpg',
        rows: [
            '쇼핑몰 플랫폼(이롬) 개발',
            '몽골 IT 산업 인력 양성 및 비즈니스 인큐베이팅 사업(KOICA) 수행',
            '몽골 헌법 재판소(KOICA) 개선 사업 현지 조사 및 번역 업무',
            '몽골 CERT 사전 타당성 조사(NIPA) 현지 조사 업무 수행',
            '한국 IT Index 개발(60개 프로젝트) 수행',
        ]
    },
    {
        title: '2017년',
        image: '/img/timeline4.jpg',
        rows: [
            '울란바토르 정보통신청/청년개발청 업무 협약 체결',
            '몽골 칭길테구 업무 협약 체결 및 TOPIK 교육',
            '몽골 고용 노동 센터 개선 프로젝트(KOICA) 현지 인력 업무 향상 교육',
        ]
    },
    {
        title: '2016년',
        image: '/img/timeline1.jpg',
        rows: [
            '9/1 칸디드 주식회사(한국 법인) 설립',
            '몽골 전문 IT 인력 양성 과정 개원(코이카 민관 협력 사업, 개발자 기초/전문가 과정, 교양과정)',
        ]
    },
    {
        title: '2011 ~ 2015년',
        image: '/img/timeline5.jpg',
        rows: [
            '몽골 전문 IT 인력 양성 과정(코이카 민관 협력 사업) 선정',
            '몽골 광해 실태 조사 GIS 구축 사업',
        ]
    }
]

const TimeLine = () => {
    return (
        <div className='container my-big'>
            <h3 className="mb-big">History</h3>
            {
                data.map((element, id) => (
                    <div key={id} className="row">
                        <div key={id}
                             className={`d-flex flex-column justify-content-center col-lg-6 ${id % 2 === 1 ? ' align-items-lg-end order-lg-1' : 'order-lg-3'}`}>
                            <h4>{element.title}</h4>
                            <ul className={id % 2 === 1 ? 'd-lg-flex flex-column align-items-end' : ''}>
                                {element.rows.map((row, rId) => (
                                    <li key={id + rId}>
                                        {row.split('\n').map((el, sId) => (
                                            <h5 key={id + rId + sId} className={id % 2 === 1 ? 'text-end' : ''}>
                                                {el}
                                            </h5>
                                        ))}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className={`col-lg-3 order-lg-2 mb-3 ${id % 2 === 1 ? '' : 'offset-lg-3'}`}>
                            <div className="object-fit ar-16-9 timeline-img">
                                <img src={element.image} alt=""/>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default TimeLine;
