import React, {Fragment, useState} from 'react';
import {Link} from "react-router-dom";
import FadeEffect from "./FadeEffect";
import {xStore} from "../utils/mobXstore";
import {observer} from "mobx-react-lite";
import {localBaseURL} from "../utils/fetcher";

const Portfolio = ({scrollY}) => {
    const [showRows, setShowRows] = useState(3)
    const addRow = () => {
        setShowRows(p => p + 1)
    }

    return (
        <div className='position-relative section py-big z10 bg-pink'>
            <div
                style={{transform: `translateX(${scrollY / 8 - 375}vw) translateY(0px)`}}
                className='scrolled-text'
            >PORT
            </div>
            <div
                style={{transform: `translateX(${-scrollY / 8 + 425}vw) translateY(400px)`}}
                className='scrolled-text'
            >FOLIO
            </div>

            <div className='container'>
                <h2 className='mb-big'>
                    <span>이미 많은</span> 프로젝트<span>로 검증되었습니다.</span>
                </h2>
                <div className="row">
                    {xStore.portfolio
                        .slice()
                        .sort((a, b) => b.order - a.order)
                        .filter((_, id) => id < (3 * showRows))
                        .map((element, id) => (
                            <Fragment key={id}>
                                <FadeEffect className={`col-lg-4 ${id % 3 === 1 ? 'mt124' : 'mb124'}`}>
                                    <Link className='card-item' to={'/portfolio/' + element.id}>
                                        <div className='object-fit ar-16-9'>
                                            <img src={`${localBaseURL}/public/upload/portfolio/${element.image}`}
                                                 alt=""/>
                                            <div className='gradient'>
                                                <h3>See more</h3>
                                            </div>
                                        </div>
                                        <h4 className="mt-lg-3 mt-2">{element.title}</h4>
                                        <h5 className="mt-lg-3 mt-2">{element.subtitle}</h5>
                                    </Link>
                                </FadeEffect>
                            </Fragment>
                        ))}
                    {
                        xStore.portfolio?.length > (3 * showRows) && <div className="d-flex justify-content-center">
                            <img onClick={addRow} src="/img/add.svg" className='plus-button mt-lg-5' alt=""/>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default observer(Portfolio);
