import React from 'react';
import ServiceCards from "./ServiceCards";
import Technologies from "./Technologies";

const data = [
    {
        title: 'IT executive',
        subtitle: [`Product 개발에`, '효율성을 제공 합니다.'],
    },
    {
        title: 'Developer executive',
        subtitle: ['Resources를 최적화 하고', 'deploy 를 더 빠르게', '수행 할 수 있게 합니다'],
    },
    {
        title: 'Developer executive',
        subtitle: ['더 나은 확장성과 더 빠른 개발', '주기를 제공 합니다.'],
    },
    {
        title: 'Developer',
        subtitle: ['개발작업이 더 쉬워 집니다.'],
    }
]
const data2 = [
    {
        title: 'TTM(time to market) 단축',
        subtitle: ['개발자가 몇 초 만에 새 인스턴스를 가동하거나 사용 중지하여 빠른 배포를 통해 개발 속도를 높일 수 있습니다.']
    },
    {
        title: '확장성 및 유연성',
        subtitle: ['클라우드 컴퓨팅은 비즈니스의 유연성을 높여줍니다. 물리적 인프라에 투자하지 않고도 비즈니스 수요에 맞게 리소스와 스토리지를 빠르게 확장할 수 있습니다.']
    },
    {
        title: '비용 절감',
        subtitle: ['실제로 사용하는 리소스에 대한 비용만 지불하면 됩니다. 따라서 데이터 센터를 과도하게 빌드하거나 오버프로비저닝하는 것을 방지할 수 있고, 보다 전략적인 작업에 집 중할 수,있습니다.']
    },
    {
        title: '더욱 편리해진 공동작업',
        subtitle: ['Cloud Storage를 사용하면 사용자는 위치나 특정 기기에 얽매이지 않고 전 세계 언제 어디서나 어느 기기에서든 인터넷에 연결되어 있다면 데이터에 액세스할 수 있습니다.']
    },
    {
        title: '강화된 보안',
        subtitle: ['클라우드 컴퓨팅은 대중적인 인식에도 불구하고 다양하고 심층적인 보안 기능, 자동 유지보수, 중앙 집중식 관리 덕분에 실제로 보안 상태를 강화할 수 있습니다.']
    },
    {
        title: '데이터 손실 방지',
        subtitle: ['클라우드 제공업체는 백업 및 재해 복구 기능을 제공합니다. 긴급 상황에서나 간단한 사용자 오류 발생 시에도 데이터 손실을 방지하는 데 도움이 됩니다.']
    }
]

const Services = ({ scrollY }) => {
    return (
        <div className='position-relative overflow-hidden py-big'>
            <div style={{ transform: `translateX(${scrollY / 8 - 700}vw) translateY(0px)` }} className='scrolled-text'>
                MICRO
            </div>
            <div style={{ transform: `translateX(${-scrollY / 8 + 750}vw) translateY(400px)` }} className='scrolled-text'>
                SERVICES
            </div>

            <div className='container'>
                <h2 className='mb-big'>
                    <span>다양한</span> 개발 언어<span>와</span> 최신 트랜드 기술<span>로</span>
                    <br />
                    최적의 개발 환경<span>을 제안 합니다.</span>
                </h2>
                <ServiceCards data={data} col={3} />

                <Technologies />

                <h2 className='my-big' style={{ textAlign: 'right' }}>
                    인프라 운영 비용 절감<span>을 위한</span>
                    <br />
                    Cloud Computing 시스템<span>을 구축 합니다.</span>
                </h2>
                <ServiceCards data={data2} col={4} />
                <Technologies l={false} />

            </div>

        </div>
    );
};

export default Services;
