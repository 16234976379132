import React from 'react';
import {useForm} from "react-hook-form";

const Scene1 = ({setScene, setSceneData}) => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = (data) => {
        setSceneData({
            categories: data.categories,
            name: data.projectName,
            type: 0,
            additional: [],
            jobs: []
        })
        setScene(p => p + 1)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className='mb-4'>프로젝트 제목</h4>
            <div className='row'>
                <div className='col-lg-4'>
                    <input
                        className='form-control col-4'
                        defaultValue=""
                        placeholder='예) 온라인 쇼핑몰 홈페이지 제작'
                        {...register("projectName", {required: true})}
                    />
                    {errors.projectName && <span className='text-danger'>This field is required</span>}
                </div>
            </div>
            <h4 className='mt-4'>프로젝트 카테고리</h4>
            <p className='mb-4'>해당하는 프로젝트 카테고리를 모두 선택해 주세요.</p>
            <div className='row'>
                <div className='col-3'>
                    <label htmlFor='s1dev' className='service-card w-100 p-3'>
                        <input type="checkbox" {...register('categories', {required: true})} value='development'
                               name='categories'
                               id='s1dev'/>
                        <div className="d-flex flex-column align-items-center mt-n4">
                            <img
                                src="https://www.wishket.com/static/renewal/img/project/submit/select_card_icon_job_category_1_c.png"
                                alt=""/>
                            <h5 className='mb-0 mt-2'>개발</h5>
                        </div>
                    </label>
                </div>
                <div className='col-3'>
                    <label htmlFor='s1doc' className='service-card w-100 p-3'>
                        <input type="checkbox" {...register('categories', {required: true})} value='documentation'
                               name='categories'
                               id='s1doc'/>
                        <div className="d-flex flex-column align-items-center mt-n4">
                            <img
                                src="https://www.wishket.com/static/renewal/img/project/submit/select_card_icon_job_category_3_c.png"
                                alt=""/>
                            <h5 className='mb-0 mt-2'>기획</h5>
                        </div>
                    </label>
                </div>
                {errors.categories && <span className='text-danger'>Select at least one</span>}
            </div>
            <br/>
            <button className='btn btn-dark mb-5 px-5' type='submit'>다음</button>
        </form>
    );
};

export default Scene1;
