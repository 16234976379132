import React from 'react';
import TopBar from "../../components/TopBar";
import FadeEffect from "../../components/FadeEffect";
import Team from "./Team";
import Footer from "../../components/Footer";
import TimeLine from "./TimeLine";
import BreadCrumb from '../../components/BreadCrumb';

const AboutPage = () => {
    return (
        <>
            <TopBar />
            <div className="container about my-big">
                <BreadCrumb page="About" />
                <h2 className='mb-big'>소프트허브글로벌의 <br /> 미래를 향한 가치있는 도전</h2>
                <div className='object-fit about-banner-big'>
                    <img src="/img/banner_17.jpg" alt="" />
                </div>
                <h2 className='my-big'><span>클라이언트의</span> 궁금증,<br /><span>당사는</span> 확실히 답변 드립니다.</h2>
                <FadeEffect>
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="object-fit ar-16-9">
                                <img src="/img/banner_19.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-6 d-flex flex-column justify-content-center">
                            <div className="d-flex my-lg-3 mt-3">
                                <h3 className='me-3'>Q.</h3>
                                <h3>원격으로 진행되는 개발, 신뢰할 수 있을까?</h3>
                            </div>
                            <div className="d-flex mb-3">
                                <p className='me-3'>A.</p>
                                <p>클라이언트와의 신뢰와 개발 품질을 최우선으로 진행하며, 협업툴 및 화상회의 등을 통해 개발 진행 과정에서 원활한 커뮤니케이션과 업무 협의를 진행함으로써
                                    신뢰 기반의 개발을 수행합니다.</p>
                            </div>
                        </div>
                    </div>
                </FadeEffect>
                <FadeEffect>
                    <div className="row">
                        <div className="col-xl-6 order-xl-2">
                            <div className="object-fit ar-16-9">
                                <img src="/img/banner_11.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-6 order-xl-1 d-flex flex-column justify-content-center">
                            <div className="d-flex my-lg-3 mt-3">
                                <h3 className='me-3'>Q.</h3>
                                <h3>해외 개발 인력과의 협업이 가능할까?</h3>
                            </div>
                            <div className="d-flex mb-3">
                                <p className='me-3'>A.</p>
                                <p>당사는 몽골 과 우즈벡 현지법인을 통해 한국 클라이언트와의 협업에 대한 개발을 지속적으로 수행하고 있습니다. 한국 클라이언트와의 협업에 대한 노하우를
                                    보유하고 있는 PM이 성공적인 개발 완료 및 후속조치까지 지원합니다. 또한, 한국과의 다수 프로젝트를 수행중인 개발진은 한국향 서비스에 대한 높은
                                    이해도를 기반으로 클라이언트가 원하는 개발을 확실히 보장합니다.</p>
                            </div>
                        </div>
                    </div>
                </FadeEffect>
                <FadeEffect>
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="object-fit ar-16-9">
                                <img src="/img/banner_20.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-6 d-flex flex-column justify-content-center">
                            <div className="d-flex my-lg-3 mt-3">
                                <h3 className='me-3'>Q.</h3>
                                <h3>기술적 문제는 없을까?</h3>
                            </div>
                            <div className="d-flex mb-3">
                                <p className='me-3'>A.</p>
                                <p>당사 개발진은 '무한 학습'을 통해 기술적 완성도 향상을 기본으로 업무를 수행하고 있습니다. JAVA, PHP, ReactJS, 하이브리드앱 등
                                    클라이언트의 니즈를 100% 충족시켜 드립니다</p>
                            </div>
                        </div>
                    </div>
                </FadeEffect>
            </div>
            <Team />
            <div className="container mt-big">
                <div className="d-flex flex-column flex-lg-row align-items-lg-stretch align-items-center">
                    <div className="flex-grow-1">
                        <div className="outcard">
                            <h4>소프트허브 글로벌 <br />(한국)</h4>
                            <h5>- 국내 법인</h5>
                            <h5>- 해외법인에 100% 직접투자, <br />관리, 조정 및 모니터링</h5>
                        </div>
                    </div>
                    <img className='exchange' src="/img/exchange.svg"
                        alt="" />
                    <div className="flex-grow-1">
                        <div className="outcard">
                            <h4>소프트브레인즈 <br />(우즈베키스탄)</h4>
                            <h5>- 2022년 2월에 설립</h5>
                            <h5>- 현지 법인 20명</h5>
                            <h5>(인원 규모 확장 진행중)</h5>
                            <h5>- 한국 대학 학사/석사 출신 PM 및</h5>
                        </div>
                    </div>
                    <img className='exchange' src="/img/exchange.svg"
                        alt="" />
                    <div className="flex-grow-1">
                        <div className="outcard">
                            <h4>MKIT <br />(몽골)</h4>
                            <h5>- 2016년 설립</h5>
                            <h5>- 현지 법인 40명</h5>
                            <h5>- 7년간 100여개 고객사 시스템 <br />구축 및 IT 아웃소싱 수행 경험</h5>
                        </div>
                    </div>
                </div>
            </div>
            <TimeLine />
            <Footer />
        </>
    );
};

export default AboutPage;
