import React from 'react';

const ServiceCards = ({data, col}) => {

    return (
        <div className='row'>
            {data.map((element, id) => (
                <div key={id} className={`col-xl-${col} col-lg-6 mb-3`}>
                    <div className='service-card'>
                        <h4 className='mb-lg-3 mb-2'>{element.title}</h4>
                        {element.subtitle.map((sub, i) => (
                            <p key={i}>{sub}</p>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ServiceCards;
