import React from 'react';

const Government = () => {
    return (
        <div className='row'>
            <div className="col-12 text-center">
                <p style={{color: '#757575', fontSize: '12pt'}}>
                    대창 기업 홈페이지 : <a href="https://www.dcconst.co.kr">https://www.dcconst.co.kr</a> <br/><br/>
                    한국 지방세 연구원 홈페이지 : <a href="https://kilf.re.kr">https://kilf.re.kr</a> <br/><br/>
                    한국 양성평등원 홈페이지 : <a href="https://kigepe.or.kr">https://kigepe.or.kr</a> <br/><br/>
                    Nell Bio <a href="http://nelbio.com">http://nelbio.com</a> <br/><br/>
                    안산온마음 센터 : <a href="http://www.ansanonmaum.net">http://www.ansanonmaum.net</a> <br/><br/>
                    한국메세나 협회 : <a href="https://www.mecenat.or.kr/ko/">https://www.mecenat.or.kr/ko/</a>
                </p>
            </div>
        </div>
    );
};

export default Government;
