import React from "react";
import {ComposableMap, Geographies, Geography, Marker} from "react-simple-maps";
import geoUrl from "./world.json"
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";

const markers = [
    {
        markerOffset: -15,
        name: "SoftBrains",
        coordinates: [69.275619, 41.2957949],
        code: 'UZB'
    },
    {
        markerOffset: -15,
        name: "MKIT",
        coordinates: [106.9275684, 47.908946],
        code: 'MNG'
    },
    {
        markerOffset: -15,
        name: "SoftHub Global",
        coordinates: [126.789575, 37.862840],
        code: 'KOR'
    },
];
const MapChart = () => {
    const highlighted = ['UZB', 'KOR', 'MNG']
    const maps = {
        UZB: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11990.25631430392!2d69.2758336!3d41.2965909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8ac5625b70d9%3A0x759b02e605dc516a!2sKorean%20Air%20Tashkent%20Office!5e0!3m2!1sen!2s!4v1684924168926!5m2!1sen!2s",
        KOR: "https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d789.0493537062123!2d126.76180886552417!3d37.715043775365075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e3!4m0!4m5!1s0x357c8f9437af0907%3A0xbd8e7e7243cbeeae!2z7Z2R67Cx7IKs7J6lIOqyveq4sOuPhCDtjIzso7zsi5wg7JW864u564-ZIDgwOA!3m2!1d37.7151539!2d126.761596!5e0!3m2!1sko!2skr!4v1669726599640!5m2!1sko!2skr",
        MNG: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.4181554272573!2d106.92756837678442!3d47.908945967049874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d96923174c0fe0f%3A0x706fdd24e878373c!2sUnion%20Building!5e0!3m2!1sen!2s!4v1684924235434!5m2!1sen!2s",
    }
    const [count, setCount] = React.useState(0)
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState('UZB')
    const requestRef = React.useRef();
    const previousTimeRef = React.useRef();
    const animate = time => {
        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current;
            setCount(prevCount => (prevCount + deltaTime * 0.007) % 100);
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }

    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once

    const closeModal = (e) => {
        e.stopPropagation();
        setOpen(false)
    }
    const openMap = (id) => {
        setSelected(id)
        setOpen(true)
    }
    return (
        <>
            {open && <div className="modal-content" onClick={closeModal}>
                <div className="modal-backdrop top-0">
                    <div className="container mt-5" onClick={e => e.stopPropagation()}>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between pt-3">
                                <h1>Maps</h1>
                                <div className="d-flex align-items-center">
                                    <button className='btn bg-opacity-50 btn-outline-dark p-3' onClick={closeModal}>
                                        <img src="/img/close.svg" alt=""/>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body p-1">
                                <iframe
                                    style={{width: '100%'}}
                                    height={window.innerHeight / 3 * 2}
                                    src={maps[selected]}
                                    allowFullScreen=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <TopBar/>
            <div
                className='d-flex justify-content-center'
                onMouseEnter={() => cancelAnimationFrame(requestRef.current)}
                onMouseLeave={() => requestAnimationFrame(animate)}
            >
                <ComposableMap
                    projection="geoOrthographic"
                    projectionConfig={{
                        rotate: [(count < 50 ? count * 2 : 200 - count * 2) - 150, -30, -360],
                        scale: 430
                    }}
                    width={900}
                    height={600}
                >
                    <Geographies geography={geoUrl}>
                        {({geographies}) => {
                            return geographies.map((geo) => {
                                const isHighlighted = highlighted.indexOf(geo.id) !== -1;
                                return <Geography
                                    className={isHighlighted ? 'hover' : ''}
                                    onClick={isHighlighted ? () => openMap(geo.id) : null}
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={!isHighlighted ? "#FFF7F7" : "#d6d6ff"}
                                    // fill={isHighlighted ? "url('#lines')" : "#F6F0E9"}
                                    stroke="#d6d6ff"
                                />
                            })
                        }
                        }
                    </Geographies>
                    {markers.map(({name, coordinates, markerOffset, code}) => (
                        <Marker key={name} coordinates={coordinates}
                                onClick={() => openMap(code)}>
                            <circle r={10} style={{cursor: 'pointer'}} fill="#d6d6ff" stroke="#FFF7F7" strokeWidth={2}/>
                            <text
                                textAnchor="middle"
                                y={markerOffset}
                                style={{fontFamily: "system-ui", fill: "#5D5A6D", fontWeight: 700}}
                            >
                                {name}
                            </text>
                        </Marker>
                    ))}

                </ComposableMap>
            </div>
            <Footer/>


        </>
    );
};

export default MapChart;
