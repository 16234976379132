import React from 'react';
import {localBaseURL} from "../../utils/fetcher";

const SystemIntegration = () => {
    return (
        <div className='row'>
            <div className="col-lg-6 mb-3 d-flex flex-column justify-content-center text-center">
                <div className="pt18 fw-bold">
                    <span>쇼핑몰, e learning, SNS 서비스, AI 연동 서비스 등 <br/>
                    100개 이상의 프로젝트</span>를 수행 했습니다. <br/>
                    체계적인 프로젝트 수행 관리를 위해 <br/>
                    프로젝트 관리툴(Red Mine 등)을 <br/>
                    적극적으로 활용 합니다. <br/>
                    github등 소스 관리 툴을 활용한 개발 소스 공유와 <br/>
                    개발 서버 주기적 반영을 통해 <br/>
                    개발 상황을 고객이 직접 모니터링 할 수 있습니다.
                </div>
            </div>
            <div className="col-lg-6 mb-3">
                <img className='w-100' src={localBaseURL + "/public/filemanager/uploads/ai.jpg"} alt=""/>
            </div>
            <div className="col-lg-6 mb-3">
                <img className='w-100' src={localBaseURL + '/public/filemanager/uploads/KakaoTalk_20230525_233008480_02.png'} alt=""/>
            </div>
            <div className="col-lg-6 mb-3">
                <img className='w-100' src={localBaseURL + "/public/filemanager/uploads/_img%20(1).jpg"} alt=""/>
            </div>

            <div className="col-12 mb-3 d-flex flex-column justify-content-center text-center">
                <div className="pt18 fw-bold">
                    <span>개발 완료 후 <br/></span>
                    서비스 기획서(스토리보드) / 디자인 / 시스템 아키텍처 / ERD / DB 테이블 명세서 / 프로그램 목록 <br/>
                    단위 테스트 결과서 / 플로우차트 / 소스 / 운영자 메뉴얼 등 완성도 높은 산풀물을 제공 합니다. <br/>
                    <br/>
                    <span>프로젝트 완료 후 <br/></span>
                    최소 3개월 간의 시스템 안정화 및 하자보수를 제공 합니다.
                </div>
            </div>
        </div>
    );
};

export default SystemIntegration;
