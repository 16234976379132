import React from 'react';

const data = [
    {
        text: ['고객사의 전담팀으로 운영',
            '고객사의 업무만 100% 진행',
            '(타회사 업무하지 않음)'],
        image: '/img/teamwork1.svg'
    },
    {
        text: ['한국어에 능통한 PM을 통해서 소통 일원화, 혹은 개발자끼리 영어로도 소통 가능'],
        image: '/img/teamwork2.svg'
    },
    {
        text: [`소통툴: 슬랙, 지라, 레드마인, 구글독스, 카톡 고객사가 선호하는 툴을 활용`],
        image: '/img/teamwork3.svg'
    },
    {
        text: ['소프트웨어 관리툴:',
            'Git Hub, Git Lab'],
        image: '/img/teamwork4.svg'
    },
    {
        text: ['화상 소통:', 'Zoom, Google Meet'],
        image: '/img/teamwork5.svg'
    },
    {
        text: ['주간단위 업무 보고서 제출 (당사 CTO의 주간단위 및 정기적인 업무 직접 관리)'],
        image: '/img/teamwork6.svg'
    },

]

const Outsourcing1 = () => {
    return (
        <div className="container pt-big">
            <h2 className='mb-big text-center'>팀 운영, 소통, 관리 및 보고방식</h2>
            <div className="row">
                {data.map((el, id) => (
                    <div className="col-lg-4 mb-3">
                        <div className="card-out py-lg-5 px-lg-3 d-flex flex-lg-row flex-column">
                            <img className='m-lg-0 m-auto mb-3' src={el.image} alt=""/>
                            <div className="d-flex flex-column justify-content-center">
                                {el.text.map(t => (
                                    <p className='text-center ms-lg-4 text-lg-start'>{t}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Outsourcing1;
