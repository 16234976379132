import React, {useState} from 'react';
import TopBar from "../../../components/TopBar";
import Scene1 from "./Scene1";
import Scene2 from "./Scene2";
import Scene3 from "./Scene3";
import Scene4 from "./Scene4";
import Scene5 from "./Scene5";
import BreadCrumb from "../BreadCrumb";
import SceneSuccess from "../SceneSuccess";
import FooterBottom from "../../../components/FooterBottom";


const Project = () => {

    const [scene, setScene] = useState(0)
    const [sceneData, setSceneData] = useState({})

    const scenes = [
        <Scene1 sceneData={sceneData} setSceneData={setSceneData} setScene={setScene}/>,
        <Scene2 sceneData={sceneData} setSceneData={setSceneData} setScene={setScene}/>,
        <Scene3 sceneData={sceneData} setSceneData={setSceneData} setScene={setScene}/>,
        <Scene4 sceneData={sceneData} setSceneData={setSceneData} setScene={setScene}/>,
        <Scene5 sceneData={sceneData} setSceneData={setSceneData} setScene={setScene}/>,
        <SceneSuccess sceneData={sceneData} setScene={setScene}/>,
    ]


    return (
        <>
            <TopBar/>
            <div className='container flex-grow-1'>
                <BreadCrumb setScene={setScene} step={scene} elements={['기본 정보', '준비 상태', '프로젝트 상세', '예산 및 일정' , '미팅', /*'모집 요건', '추가 정보'*/]}/>
                {scenes[scene]}
            </div>
            <FooterBottom/>
        </>
    );
};

export default Project;
