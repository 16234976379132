import React from 'react';

const Details = () => {
    return (
        <div>
            <div className="form-group-light" style={{display: 'block'}}>
                <label className="form-group-title body-1-bold">상세 기획 상태</label>
                <p className="body-2 text900 form-group-guide">준비된 상세 기획 문서를 선택해주세요.</p>
                <div className="form-content">
                    <ul>
                        <li className="mb12">
                            <label className="d-flex align-items-center">
                                <input
                                    className="theme-client not-required-field wishket-checkbox me-3"
                                    id="detail_plan_status_spec_document"
                                    name="detail_plan_status"
                                    type="checkbox"
                                    value="spec_document"
                                />
                                <div>
                                    <span>요구사항 정의서</span>
                                    <p>구현을 원하는 기능과 그에 대한 설명, 작업 우선순위 등이 작성된 문서입니다.</p>
                                </div>
                            </label>
                        </li>
                        <li className="mb12">
                            <label className="d-flex align-items-center">
                                <span>
                                    <input
                                        className="theme-client not-required-field wishket-checkbox me-3"
                                        id="detail_plan_status_screen_document"
                                        name="detail_plan_status"
                                        type="checkbox"
                                        value="screen_document"
                                    />
                                </span>
                                <div><span>화면설계서 (스토리보드, 와이어프레임)</span><p>제작될 결과물의 UI 설계와 기능에 대한 설명이 기재된 문서입니다.</p>
                                </div>
                            </label></li>
                        <li className="mb12">
                            <label className="d-flex align-items-center">
                                <span>
                                    <input
                                        className="theme-client not-required-field wishket-checkbox me-3"
                                        id="detail_plan_status_prototype" name="detail_plan_status" type="checkbox"
                                        value="prototype"/>
                                    <span className="arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                            <g fill="none" fillRule="evenodd">
                                                <path className="checked-icon" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6" d="M4 7.8L7 11l5-6"/>
                                            </g>
                                        </svg>
                                    </span>
                                </span>
                                <div>
                                    <span>프로토타입 (프로젝트 결과물의 샘플 버전)</span>
                                    <p>중요한 기능으로만 구성된 프로젝트 결과물의 샘플 버전입니다.</p>
                                </div>
                            </label>
                        </li>
                        <li className="mb12">
                            <label className="d-flex align-items-center">
                                <span>
                                    <input className="theme-client not-required-field wishket-checkbox me-3" id="detail_plan_status_request_document" name="detail_plan_status" type="checkbox" value="request_document"/>
                                    <span className="arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                            <g fill="none" fillRule="evenodd">
                                                <path className="checked-icon" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6" d="M4 7.8L7 11l5-6"/>
                                            </g>
                                        </svg>
                                    </span>
                                </span>
                            <div>
                                <span>제안요청서</span>
                                <p>제안을 받기 위한 요청서로 프로젝트 목적, 요구사항, 제안서 양식, 평가 기준 등이 기재된 문서입니다.</p>
                            </div>
                        </label></li>
                        <li className="mb12"><label className="d-flex align-items-center"><span><input
                            className="theme-client not-required-field wishket-checkbox me-3"
                            id="detail_plan_status_information_architecture" name="detail_plan_status" type="checkbox"
                            value="information_architecture"/><span className="arrow"><svg
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><g fill="none" fillRule="evenodd"><path
                            className="checked-icon" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round"
                            strokeWidth="1.6" d="M4 7.8L7 11l5-6"></path></g></svg></span></span>
                            <div><span>메뉴구조도 (IA)</span><p>제작될 결과물의 정보가 메뉴 단위로 설계된 문서입니다.</p></div>
                        </label></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Details;
