import React from "react";
import { Link } from "react-router-dom";

export default function BreadCrumb({ page }) {
  return (
    <div className="custom-breadcrumb">
      <Link to="/">Home</Link>
      <img
        className="arrow"
        src="/img/right-arrow.svg"
        alt="arrow"
        width="18px"
        height="16px"
      />
      <span>{page}</span>
    </div>
  );
}
