import React from 'react';

const data = [
    '고객사가 원하는 모든 보안 준수 요건을 따릅니다.',
    '고객사별 보안 서약서 (회사, CTO, 투입 개발자 모두)를 작성 합니다.',
    '원천 기술의 경우 외주를 주지 않고 고객사가 직접 관리하거나, 개발자를 국내 파견 시켜서 직접 관리 감독 하는 것을 추천 합니다.',
    '고객사의 VPN을 통해서 고객사의 내부망에서만 개발 작업을 하는 방법도 가능 합니다',
    '당사는 내부에 “보안담당자＂를 지정하여 정기적으로 “데이터 폐기, 문서 관리, 잠금, PC관리”를 통해 보안 점검을 실시 합니다.',
    '당사는 재택근무를 허용하지 않고 오직 사무실에서 회사 PC로만 작업을 시키며 외부로의 PC반출을 금지하고 있습니다.',
]

const Outsourcing1 = () => {
    return (
        <div className="container py-big">
            <h2 className='mb-big text-center'>보안 유지</h2>
            <div className="row">
                {data.map((el, id) => (
                    <div key={id} className="col-lg-4 mb-3">
                        <div className="card-out">
                            <p className='fw-normal'>{el}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Outsourcing1;
