import React from 'react';
import {localBaseURL} from "../../utils/fetcher";

const Shopping = () => {
    return (
        <>
            <div className='row'>
                <div className="col-lg-6 mb-3 d-flex flex-column justify-content-center text-center">
                    <div className="pt18 fw-bold">
                        다양한 개발 플랫폼을 활용 <br/>
                        고객의 Needs에 부응 하는 쇼핑몰 서비스를 구축 하였습니다. <br/>
                        Java Spring boot / reactJS / IOS / AOS / .Net
                    </div>
                </div>
                <div className="col-lg-6 mb-3">
                    <img className='w-100' src={localBaseURL + "/public/filemanager/uploads/KakaoTalk_20230525_233008480_03.png"} alt=""/>
                </div>
                <div className="col-lg-6 mb-3">
                    <img className='w-100' src={localBaseURL + "/public/filemanager/uploads/shop.jpg"} alt=""/>
                </div>
                <div className="col-lg-6 mb-3 d-flex flex-column justify-content-center text-center">
                    <div className="pt18 fw-bold">
                        React JS 기반 반응형 웹 <br/>
                        JAVA 기반 보안성 강화 <br/>
                        서비스 동적 관리 <br/>
                        마케팅 기능 강화
                    </div>
                </div>
            </div>
            <div className="row buttered pt-big">
                <div className="col-lg-3 ">
                    <div className="p-3 fw-bold h-100 d-flex align-items-center"
                         style={{fontSize: '12pt', color: '#E67E23'}}>
                        React JS 기반 반응형 웹
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="p-3 h-100">
                        React JS 기반의 반응형 웹으로 구축되어 PC/테블릿/스마트폰 구분 없이 자동으로 최적의 UI를 제공 합니다.
                        <br/>
                        단일 플랫폼으로 유지보수 비용을 줄여 주며 운영 효율성을 향상 시킵니다.
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="p-3 fw-bold h-100 d-flex align-items-center"
                         style={{fontSize: '12pt', color: '#E67E23'}}>
                        JAVA 기반 보안성 강화
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="p-3 h-100">
                        <span className='fw-bolder'>▶ 캡슐화(은닉성) <br/></span>
                        자바 프로그래밍은 클래스에 담는 중요한 데이터나 기능을 외부에서 접근 하지 못하게 하기 위해
                        <span className='fw-bolder'>캡슐화(Encapsulation)를</span> 사용 하여 <br/>
                        <span className='fw-bolder'>프로그램 자체의 보안성</span>이 기존 쇼핑몰 제작에 많이 사용 되는 PHP, ASP 프로그램에 비해 탁월 합니다.
                        <br/>
                        <span className='fw-bolder'>▶ 네트워크 분산 처리 환경 <br/></span>
                        자바는 원래 부터 <span className='fw-bolder'>네트워크 분산 처리 환경에서 사용 하기 위해 디자인된 언어</span>
                        로 보안의 측면이 강조 되는 네트워크 환경상 보안에 중점을 두고 있으며 <span className='fw-bolder'>바이러스가 침투하기 어려운 구조</span>
                        를 가지고 메모리에서 <span className='fw-bolder'>데이터 접근을 제한</span> 할 수 있어 <span className='fw-bolder'>해킹 방지에 효과</span>적
                        입니다. <br/>
                        <span className='fw-bolder'>▶ 다양한 Security 방안 <br/></span>
                        자바에서는 <span>Security 알고리즘이 다양하게 제공</span> 되며 특정 파일을 인코잉, 디코딩 하여 보안을 철저히 하고 싶을 때 또는 DB 설정 파일을 보안화
                        하여 주요 기능 및
                        데이터에 대한 <span>악의 적인 접근을 차단 </span>할 수 있습니다.
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="p-3 fw-bold h-100 d-flex align-items-center"
                         style={{fontSize: '12pt', color: '#E67E23'}}>
                        서비스 동적 관리
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="p-3 h-100">
                        관리자 서비스를 이용 하여 메뉴, 상품 카테고리, 메인 베너, 이벤트, 프로모션, 회사 정보등을 운영자가 직접 관리 할 수 있습니다.
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="p-3 fw-bold h-100 d-flex align-items-center"
                         style={{fontSize: '12pt', color: '#E67E23'}}>
                        마케팅 기능 강화
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="p-3 h-100">
                        afiliate(링크 공유를 통한 홍보 및 수익 배분) 및 SNS 공유등을 통하여 상품 홍보/마케팅 서비스 기능을 강화 했습니다.
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="p-3 fw-bold h-100 d-flex align-items-center"
                         style={{fontSize: '12pt', color: '#E67E23'}}>
                        기본 기능
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="p-3 h-100">
                        <span className='fw-bolder'>▶ 사용자</span> <br/>
                        로그인(id/pwd, SNS 로그인) / 쿠폰, 적립금 / 검색 / 상품 필터 / 퀵메뉴 / 추천상품 및 연관 상품 / 위시리스트 /고객센터 / SNS 공유 / 1:1 상담
                        / 리뷰(포토/텍스트 리뷰 작성 등) / 장바구니 / 접속위치 기반 주소 설정 / Affiliate / Mypage <br/>
                        <span className='fw-bolder'>▶ 관리자</span> <br/>
                        <span className='fw-bolder'>상품관리</span> : 상품 엑셀 일괄 등록 및 상품 개별 등록 / 세트 상품 설정 / 카테고리 설정 / 상품 정보 일괄
                        다운로드 및 개별 다운로드 / 재고관리 <br/>
                        <span className='fw-bolder'>회원관리</span> : 등급별 회원 관리 / 휴면 회원 관리 / 탈퇴회원 / CRM / 쿠폰 / 적립금 / 메일 전송 /
                        1:1 상담 <br/>
                        <span className='fw-bolder'>주문관리</span> : 단계별 배송 설정 / 주문서 출력 / 환불, 취소 내역 조회 <br/>
                        <span className='fw-bolder'>통계관리</span> : 기간별 방문 통계 / 기간별 매출통계 / 상품별 매출통계 / 판매, 조회, 검색 랭킹 / 연령별
                        통계 / 국가별 통계 /방문자 환경 통계 <br/>
                        <span className='fw-bolder'>프로모션 관리</span> : MD’s Pick / Time Deal / N+1 / 할인 / 무료배송 / 기획전 / 마케팅
                        메일 / 쿠폰 / 스티커 <br/>
                        <span className='fw-bolder'>베너 관리</span> : 메인 / 이벤트 베너 관리 <br/>
                        <span className='fw-bolder'>결제 관리</span> : Paypal / Eximbay 관리 <br/>
                        <span className='fw-bolder'>배송 관리</span> : 배송비 설정 / 국가별 배송 설정 / DHL 연동 및 우체국 연동을 통한 배송조회 <br/>
                        <span className='fw-bolder'>팝업 관리</span> <br/>
                        <span className='fw-bolder'>기본정보 관리</span> : 기본정보 입력/수정, 공지사항 / 회사소개 정보 수정 변경 / 약관/정책
                        조회/수정 <br/>
                        <span className='fw-bolder'>▶ Seller</span> <br/>
                        판매자 등록 / 상품 등록 / 입고 신청, 관리 / 정산
                    </div>
                </div>
            </div>
        </>
    );
};
export default Shopping;
