import React from 'react';

const chunkArray = (arr, by = 4) => {
    const n = Math.ceil(arr.length / by)
    const chunkLength = Math.max(arr.length / n, 1);
    const chunks = [];
    for (let i = 0; i < n; i++) {
        if (chunkLength * (i + 1) <= arr.length) {
            chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
        }
    }
    return chunks;
}

const data1 = [
    {
        image: '/img/mysql.svg',
        text: 'MySQL'
    },
    {
        image: '/img/mongodb.svg',
        text: 'MongoDB'
    },
    {
        image: '/img/mariadb.svg',
        text: 'MariaDB'
    },
    {
        image: '/img/oracle.svg',
        text: 'Oracle'
    },
    {
        image: '/img/angular.svg',
        text: 'Angular'
    },
    {
        image: '/img/laravel.svg',
        text: 'Laravel'
    },
    {
        image: '/img/python.svg',
        text: 'Python'
    },
    {
        image: '/img/nodejs.svg',
        text: 'NodeJS'
    },
    {
        image: '/img/vuejs.svg',
        text: 'VueJS'
    },
    {
        image: '/img/reactjs.svg',
        text: 'ReactJS'
    },
    {
        image: '/img/html5.svg',
        text: 'HTML5'
    },
    {
        image: '/img/css3.svg',
        text: 'CSS3'
    },
    {
        image: '/img/spring.svg',
        text: 'Spring Boot'
    },
    {
        image: '/img/blockly.svg',
        text: 'Blockly'
    },
    {
        image: '/img/postgresql.svg',
        text: 'PostgreSQL'
    },
    {
        image: '/img/dynamodb.svg',
        text: 'DynamoDB'
    }
]
const data2 = [
    {
        image: '/img/amazon (1).svg',
        text: 'Amazon ECS'
    },
    {
        image: '/img/amazon (2).svg',
        text: 'AWS'
    },
    {
        image: '/img/amazon (8).svg',
        text: 'Docker'
    },
    {
        image: '/img/amazon (4).svg',
        text: 'Load Balancer'
    },
    {
        image: '/img/amazon (5).svg',
        text: 'AWS EC2'
    },
    {
        image: '/img/amazon (6).svg',
        text: 'AWS Fargate'
    },
    {
        image: '/img/amazon (7).svg',
        text: 'AWS Lambda'
    },
    {
        image: '/img/amazon-s3.svg',
        text: 'AWS S3'
    }
]

const Technologies = ({l = true}) => {
    const data = l ? data1 : data2
    return (
        <div className='row my-lg-4 my-3'>
            {chunkArray(data, 4).map((group, gId) => (
                <div className='col-xl-6' key={gId}>
                    <div className='row mb-lg-5 mb-3'>
                        {group.map((element, id) => (
                            <div className='col-3 tech-card ' key={`${gId}-${id}`}>
                                <img src={element.image} alt="" height={100}/>
                                <p className='my-1 position-absolute'>{element.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Technologies;
