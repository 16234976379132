import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade} from "swiper";
import parse from 'html-react-parser';
import {fetcher, localBaseURL} from "../utils/fetcher";
import {Link} from "react-router-dom";

const MainSlider = () => {

    const [data, setData] = useState([])

    useEffect(() => {
        fetcher.get('/api/banner/getAll')
            .then(res => {
                setData(res.data.data)
            })
    }, [])
    return (
        <Swiper
            effect={"fade"}
            speed={2000}
            className='main-slider'
            modules={[Autoplay, EffectFade]}
            slidesPerView={1}
            loop={true}
            autoplay={{delay: 3000, disableOnInteraction: false}}
        >
            {
                data.map((element, id) => (
                    <SwiperSlide key={id}>
                        <div className='main-slider__bg object-fit'>
                            <img
                                className='position-absolute'
                                src={`${localBaseURL}/public/upload/banner/${element.image}`}
                                alt=""
                            />
                            <video className='video-bg' width="320" height="240" muted autoPlay loop>
                                <source src={`${localBaseURL}/public/upload/banner/${element.image}`}
                                        type="video/mp4"/>
                            </video>
                            <div className="container main-slider__inner">
                                {parse(element.title || '')}
                                <div className="banner-subtitle">
                                    {parse(element.text_2 || '')}
                                    {parse(element.text_3 || '')}
                                </div>
                                <div className='flex-grow-1'/>
                                <Link to={element.url}>
                                    <button className='cta'>
                                        자세히보기
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>);
};

export default MainSlider;

// 2 admin
