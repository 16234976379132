import {makeAutoObservable} from "mobx";
import {fetcher} from "./fetcher";

class Store {
    news = []
    portfolio = []
    faq = []
    team = []
    info = [
        {
            id: 8,
            title: "개발팀 IT 아웃소싱 서비스",
            subtitle: "다양한 개발 언어와 최신 트랜드 기술을 보유한 해외 개발 인력이 고객의 내부 개발팀처럼 업무를 수행 합니다",
            image: "20230517223732_list.webp",
        },
        {
            id: 3,
            title: "System Integration (SI)",
            subtitle: "공공 및 일반 기업의  IT 서비스를 구축 합니다. 당사는 2016년 이후 100여개의 다양한 프로젝트를 수행 했습니다.",
            image: "20230517224727_list.webp",
        },
        {
            id: 18,
            title: "신기술 개발",
            subtitle: "블록체인 기술 기반 Smart Contract / Coin 개발, IOT 서비스 관련 기술 개발, 블록코딩 서비스 개발",
            image: "20230517225317_list.webp",
        },
        {
            id: 2,
            title: "E-Learning Platform 개발",
            subtitle: "스터디맥스, 이투스, 뤼이드, 메스레볼루션, 리치프렙, 유니월드 등 E-Learnig 서비스 제공을 위한 시스템 설계/디자인/Front/Back-End 개발을 수행 했습니다.",
            image: "20230517224947_list.webp",
        },
        {
            id: 1,
            title: "온라인 쇼핑몰 구축",
            subtitle: "다양한 개발 플랫폼을 활용 고객의 Needs에 부응하는 쇼핑몰 서비스를 구축하였습니다. ReactJS, Java Spring Framework, .NET 기반의 쇼핑몰 서비스 구축의 경험과 노하우를 바탕으로 안정적인 서비스를 제공해드립니다.",
            image: "20230527144308_list.webp",
        },
        {
            id: 17,
            title: "전자정부 웹서비스 구축",
            subtitle: "전자정부 프레임웍(Java Spring Framework) 기반의 다양한 웹서비스를 구축 하였습니다.",
            image: "20210331021226_list.webp",
        },
    ]

    constructor() {
        makeAutoObservable(this);
        fetcher.get('/api/news/getAll')
            .then(res => {
                this.news = res.data.data?.reverse()
            })
        fetcher.get('/api/portfolio/getAll')
            .then(res => {
                this.portfolio = res.data.data?.reverse()
            })
        /*fetcher.get('/api/info/getAll')
            .then(res => {
                this.info = res.data.data
            })*/
        fetcher.get('/api/faq/getAll')
            .then(res => {
                this.faq = res.data.data
            })
        fetcher.get('/api/team/getAll')
            .then(res => {
                this.team = res.data.data
            })
    }

}

export const xStore = new Store();
