import React from 'react';
import {useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import Outsourcing from "./outsourcing";
import SystemIntegration from "./systintegro";
import NewTechnologies from "./newTech";
import ELearning from "./eLearning";
import Shopping from "./shopping";
import Government from "./government";

const InfoItem = () => {
    const {id} = useParams()

    const pages = {
        '3': <SystemIntegration/>,
        '8': <Outsourcing/>,
        '18': <NewTechnologies/>,
        '2': <ELearning/>,
        '1': <Shopping/>,
        '17': <Government/>,
    }

    if (id === '8') {
        // return <SystemIntegration/>
        return <Outsourcing/>
    }
    return (
        <>
            <TopBar/>
            <div className="justify-content-center container">
                <div className='parsed-content py-big'>
                    {pages[id]}
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default observer(InfoItem);
