import React from 'react';
import {useForm} from "react-hook-form";
import Details from "./Details";

const Scene2 = ({setScene, sceneData, setSceneData}) => {
    const {register, handleSubmit, watch} = useForm({
        defaultValues: {
            isNew: 'true',
            planningStatus: '1'
        }
    });
    const onSubmit = (data) => {
        setSceneData({...sceneData, ...data, isNew: data.isNew === "true" ? 1 : 0})
        setScene(p => p + 1)
    }

    const projectStatus = watch("planningStatus")

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <h4 className='mb-4'>프로젝트 진행 분류</h4>

            <label htmlFor="s2class1">
                <input
                    {...register("isNew")}
                    type="radio"
                    className='me-2 mb-3'
                    value="true"
                    id="s2class1"
                />
                신규 프로젝트
            </label>
            <br/>
            <label htmlFor="s2class2">
                <input
                    {...register("isNew")}
                    type="radio"
                    className='me-2 mb-3'
                    value="false"
                    id="s2class2"
                />
                리뉴얼 또는 유지 보수
            </label>

            <h4 className='my-4'>기획상태</h4>
            <div className="row mb-4">
                <div className='col-3'>
                    <label htmlFor='s2pstat1' className='service-card w-100 p-3'>
                        <input type="radio" id='s2pstat1' value='1' {...register("planningStatus")}/>
                        <div className="d-flex flex-column align-items-center mt-n4">
                            <img
                                src="https://www.wishket.com/static/renewal/img/project/submit/select_card_icon_planning_status_1_c.png"
                                alt=""/>
                            <p className='mb-0 text-center mt-2'>아이디어만 있습니다.</p>
                        </div>
                    </label>
                </div>
                <div className='col-3'>
                    <label htmlFor='s2pstat2' className='service-card w-100 p-3'>
                        <input type="radio" id='s2pstat2' value='2' {...register("planningStatus")}/>
                        <div className="d-flex flex-column align-items-center mt-n4">
                            <img
                                src="https://www.wishket.com/static/renewal/img/project/submit/select_card_icon_planning_status_2_c.png"
                                alt=""/>
                            <p className='mb-0 text-center mt-2'>필요한 내용들을 간단히<br/>정리해두었습니다.</p>
                        </div>
                    </label>
                </div>
                <div className='col-3'>
                    <label htmlFor='s2pstat3' className='service-card w-100 p-3'>
                        <input type="radio" id='s2pstat3' value='3' {...register("planningStatus")}/>
                        <div className="d-flex flex-column align-items-center mt-n4">
                            <img
                                src="https://www.wishket.com/static/renewal/img/project/submit/select_card_icon_planning_status_3_c.png"
                                alt=""/>
                            <p className='mb-0 text-center mt-2'>상세한 기획 문서가<br/>존재합니다.</p>
                        </div>
                    </label>
                </div>
            </div>
            {projectStatus.toString() !== '1' && <Details/>}
            <h4>프로젝트 관련 자료</h4>
            <p>
                아이디어 또는 필요한 내용을 정리한 문서를 추가해 주세요.
                <br/>
                프로젝트 예산 및 일정 산정에 활용되며, 문서 및 이미지 파일만 추가 가능합니다.
            </p>
            <div className="row mt-4">
                <div className="col-lg-4">
                    <input className='form-control' {...register('files')} type="file"/>
                </div>
                <div className="col-lg-4">
                    <button type='submit' className='btn btn-dark mb-5 px-5'>다음</button>
                </div>
            </div>
        </form>
    );
};

export default Scene2;
