import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade} from "swiper";

const menu = [
    {
        text: `Project\u00A0Request`,
        link: '/create',
    },
    {
        text: `About\u00A0Us`,
        link: '/about',
    },
    {
        text: 'Business',
        link: '/business',
    },
    {
        text: 'News',
        link: '/news',
    },
    {
        text: 'Team',
        link: '/team',
    },
    {
        text: 'Tech\u00A0info',
        link: '/techInfo',
    },
    {
        text: 'Contact',
        link: '/contact',
    },
]

const TopBar = ({watcher = false}) => {
    const [scrolled, setScrolled] = useState(watcher)
    const [active, setActive] = useState('')
    const [open, setOpen] = useState(false)
    const {pathname} = useLocation()
    const onScroll = () => {
        setScrolled(window.scrollY < window.innerHeight)
    }
    useEffect(() => {
        setScrolled(watcher)
        setActive(pathname.slice(1).replaceAll(/\/.+/g, ''))
        if (watcher) {
            window.addEventListener('scroll', onScroll)
        }
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [pathname])

    const handleOpenClose = () => {
        setOpen(p => !p)
    }
    return (
        <div className={`nav-holder `}>
            <div className={`fixed-nav ${!scrolled && 'bg-dark'}`}>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-2 col-6 d-flex'>
                            <Link to='/'>
                                <Swiper
                                    effect={"fade"}
                                    className='logo-slider py-3'
                                    modules={[Autoplay, EffectFade]}
                                    slidesPerView={1}
                                    loop={true}
                                    autoplay={{delay: 1000}}
                                >
                                    <SwiperSlide>
                                        <img src="/img/softhublogo.png" alt=""/>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/img/softbrainslogo.png" alt=""/>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/img/mkitlogo.png" alt=""/>
                                    </SwiperSlide>
                                </Swiper>
                            </Link>
                        </div>
                        <div className='col-lg-8 d-none d-lg-flex justify-content-end align-items-center navlinks'>
                            {menu.map((element, id) => (
                                <Link key={id}
                                      className={`ms-xl-5 ms-4 ${element.link.slice(1) === active ? 'active' : ''}`}
                                      to={element.link}>{element.text}</Link>
                            ))}
                            <div id="google_translate_element" className='d-none d-lg-inline-block ms-xl-5 ms-4'/>

                        </div>
                        <div className="col-6 d-flex d-lg-none justify-content-end align-items-center">
                            <button className='btn bg-opacity-50 btn-outline-light' onClick={handleOpenClose}>
                                <img src="/img/menu.svg" alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`d-lg-none menu-mobile ${open && 'open'}`}>
                    <div className="container">
                        <div className="row navlinks mb-3">
                            {menu.map((element, id) => (
                                <div key={id} className="col-6 d-flex justify-content-center">
                                    <Link
                                        className={`my-2 text-white ${element.link.slice(1) === active ? 'active' : ''}`}
                                        to={element.link}>{element.text}</Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
