import React from 'react';
import {useParams} from "react-router-dom";
import {xStore} from "../../utils/mobXstore";
import {observer} from "mobx-react-lite";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import parse from "html-react-parser";
import {localBaseURL} from "../../utils/fetcher";

const NewsItem = () => {
    const {id} = useParams()
    const news = xStore.news.filter(el => el.id.toString() === id)[0]
    return (
        <>
            <TopBar/>
            <div className="container py-big">
                <h1 className='col-lg-8 offset-lg-2 text-center mb-big'>{news?.title}</h1>
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 parsed-content">
                        {parse(news?.content.replaceAll('../../../', localBaseURL+'/') || '')}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default observer(NewsItem);
