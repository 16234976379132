import React from 'react';
import {useForm} from "react-hook-form";

const Scene3 = ({setScene, sceneData, setSceneData}) => {
    const {register, handleSubmit} = useForm();
    const onSubmit = (data) => {
        setSceneData({...sceneData, ...data})
        setScene(p => p + 1)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className='mb-4'>프로젝트 상세</h4>
            <div className='row'>
                <div className='col-lg-8'>
                    <textarea
                        className='form-control col-4'
                        defaultValue=""
                        placeholder='예) 온라인 쇼핑몰 홈페이지 제작'
                        {...register("detail")}
                        cols={200}
                        rows={10}
                    />
                </div>
            </div>
            <br/>
            <button className='btn btn-dark mb-5 px-5' type='submit'>다음</button>
        </form>
    );
};

export default Scene3;
