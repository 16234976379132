import React from 'react';
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import { xStore } from "../../utils/mobXstore";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import BreadCrumb from '../../components/BreadCrumb';

const FaqPage = () => {
    return (
        <>
            <TopBar />
            <div className="container my-big">
                <BreadCrumb page="Tech info" />
                <h2 className='mb-big'>Tech info</h2>
                <table className="table">
                    <tbody>
                        {xStore.faq.map((element, id) => (
                            <tr key={id}>
                                <th className='py-lg-3' style={{ cursor: "pointer", width: 50 }}>
                                    <Link className='text-black' to={`/techInfo/${element.id}`}>
                                        {id + 1}
                                    </Link>
                                </th>
                                <td className='py-lg-3'>
                                    <Link className='text-black' to={`/techInfo/${element.id}`}>
                                        <h5 style={{ cursor: "pointer" }}
                                            className='w-100 d-block text-black'>{element.title}</h5>
                                    </Link>
                                    {/*
                                <div className='el'>
                                    <div className='d-block my-3 text-black'>{element.subtitle}</div>
                                </div>
*/}
                                </td>
                                <td className='pt-lg-4' style={{ cursor: "pointer", width: 50 }}>
                                    <Link className='text-black' to={`/techInfo/${element.id}`}>
                                        <img className='arrow' src="/img/right-arrow.svg"
                                            alt="arrow" />
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
            <Footer />
        </>
    );
};

export default observer(FaqPage);
