import React from 'react';
import TopBar from "../../../components/TopBar";
import FooterBottom from "../../../components/FooterBottom";
import Outsourcing1 from "./outsourcing1";
import Outsourcing2 from "./outsourcing2";
import Outsourcing3 from "./outsourcing3";
import {Link} from "react-router-dom";
import Outsourcing4 from "./outsourcing4";

const Outsourcing = () => {
    return (
        <>
            <TopBar/>
            <div className="dark-bg-text text-lg-center py-big">
                <div className="container">
                    주변의 네이버, 카카오를 비롯해서 돈 많다고 알려진 삼성 전자나, <br/>
                    배달의 민족 까지 원하는 수준의 개발자를 구하지 못해서 난리 입니다. <br/>
                    연봉을 부르는 대로 준다고 해도 어렵습니다. <br/>
                    언제든 좋은 일자리로 점프 할 수 있는 환경에 워라벨을 중시하는 <br/>
                    사회 문화 속에서 중소 개발사들은 더더욱 힘들어지고 있습니다. <br/>
                    <br/>
                    다양한 개발 언어와 최신 트랜드 기술을 보유한 해외 개발 인력이 <br/>
                    고객의 내부 개발팀처럼 업무를 수행 합니다<br/>
                    <br/>
                    <b>개발 비용 절감을 통해 기업의 경쟁력을 높이세요</b>
                </div>
            </div>
            <Outsourcing1/>
            <Outsourcing2/>
            <Outsourcing3/>
            <Outsourcing4/>
            <div className="container d-flex align-items-center flex-column mb-big"
                 style={{backgroundColor: '#393737', padding: '100px 0'}}>
                <img width={258} src="/img/softhublogobig.png" alt=""/>
                <h1 style={{padding: '60px 0'}} className='text-center mx-3 text-white'>
                    당사는 개발 프로젝트 외에도 유지보수 <br/>프로젝트를 다수 진행하고 있습니다.
                </h1>
                {/*<Link to='/'>*/}
                <Link to='/create'>
                    <button className="btn btn-light btn-muni">
                        문의하기
                        {/*<img className='ms-3' src="/img/arrowupright.svg" alt=""/>*/}
                    </button>
                </Link>
            </div>
            <FooterBottom/>
        </>
    );
};

export default Outsourcing;
