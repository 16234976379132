import React from 'react';
import {useParams} from "react-router-dom";
import {xStore} from "../../utils/mobXstore";
import {observer} from "mobx-react-lite";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import parse from "html-react-parser";
import {localBaseURL} from "../../utils/fetcher";

const PortfolioItem = () => {
    const {id} = useParams()
    const data = xStore?.portfolio?.filter(el => el.id.toString() === id)[0]
    return (
        <>
            <TopBar/>
            <div className="container my-big">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        {/*<img className='iw-100' src={`${localBaseURL}/public/upload/portfolio/${data?.image}`} alt=""/>*/}
                        <h1 className='text-center mb-lg-5 mb-3'>{data?.title}</h1>
                        {parse(data?.content.replaceAll('../../../', localBaseURL+'/') || '')}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default observer(PortfolioItem);
