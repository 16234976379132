import React from 'react';

const BreadCrumb = ({elements, step, setScene}) => {
    return (
        <div className='d-flex py-3 btn-group'>
            {elements.map((item, id) => (
                <React.Fragment key={id}>
                    <button
                        disabled={step < id}
                        onClick={() => setScene(id)}
                        // className='btn btn-primary'
                        className={'btn ' + (step > id ? 'btn-success' : step === id ? ' btn-primary' : 'btn-secondary')}
                    >
                        {item}
                    </button>
                    {/*<img src="/img/arrow.svg" className='mx-3' alt=""/>*/}
                </React.Fragment>
            ))}
        </div>
    );
};

export default BreadCrumb;
