import React from 'react';

const Outsourcing1 = () => {
        const data = [
            {
                title: 'Back End',
                elements: [
                    'Java',
                    'Spring Frame',
                    'Spring Boot',
                    'NodeJS',
                    '전자정부프레임웍',
                ],
            },
            {
                title: 'Front End',
                elements: [
                    'ReactJS',
                    'NextJS',
                    'VUE JS',
                    'JavaScript',
                ]
            },
            {
                title: 'Mobile',
                elements: [
                    'Android',
                    'IOS',
                    'React Native',
                    'Flutter',
                ]
            },

            {
                title: 'DB',
                elements: [
                    'MySQL',
                    'MariaDB',
                    'MSSQL',
                    'GraphQL',
                    'PostGre',
                ]
            }
        ]
        return (
            <div className='container pt-big'>
                <h2 className='mb-3 text-center'>고객사에 투입될 개발팀 구성</h2>
                <h3 className='fw-normal text-lg-center'>업무지원(PM 혹은 통역/번역 담당자) 1명 • 개발자: 중급 2명, 중초급1명, 초급 1명</h3>
                <h4 className='fw-normal text-lg-center my-lg-4 my-3'>※ 5명단위의 팀이 적정하나 개발자 등급 및 인원은 고객 요청에 의해 조정 가능 (최소&nbsp;3명&nbsp;이상)</h4>
                <div className="row">
                    {data.map((element, id) => (
                        <div key={id} className="col-6 col-lg-3 mb-3">
                            <div className="outcard">
                                <h4 className='mb-3'>{element.title}</h4>
                                {element.elements.map((tech, tid) => (
                                    <p className='mb-1' key={tid}>{tech}</p>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
;

export default Outsourcing1;
