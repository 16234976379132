import React from 'react';
import TopBar from "../../components/TopBar";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { xStore } from "../../utils/mobXstore";
import { observer } from "mobx-react-lite";
import FadeEffect from "../../components/FadeEffect";
import { localBaseURL } from "../../utils/fetcher";
import BreadCrumb from '../../components/BreadCrumb';

const NewsPage = () => {
    return (
        <>
            <TopBar />
            <div className="container mt-big mb-lg-5 mb-4">
                <BreadCrumb page="News" />
                <h2 className='mb-big'>News</h2>
                <div className="row">
                    {xStore.news.map((element, id) => (
                        <div key={id} className="col-xl-4 col-lg-6 mb-lg-5 mb-3">
                            <FadeEffect>
                                <Link className='card-item ellipsis' to={'/news/' + element.id}>
                                    <div className="object-fit ar-16-9">
                                        <img alt='' src={`${localBaseURL}/public/upload/news/${element.image}`} />
                                        <div className="gradient" />
                                    </div>
                                    <h4 className='mt-lg-3 mt-2'>{element.title}</h4>
                                    <h5 className='mt-lg-3 mt-2'>{element.subtitle}</h5>
                                    <h6 className='mt-lg-2 mt-1 text-secondary'>{element.created_at}</h6>
                                </Link>
                            </FadeEffect>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default observer(NewsPage);
