import React from 'react';
import {motion} from "framer-motion";

const FadeEffect = ({children, ...props}) => {
    const variants = {
        visible: {opacity: 1, scale: 1},
        hidden: {opacity: 0, scale: 0},
        exit: {opacity: 0, scale: 0}
    }

    return (
        <motion.div
            initial="hidden"
            exit='exit'
            whileInView="visible"
            viewport={{once: true}}
            transition={{duration: 0.3}}
            variants={variants}
            {...props}
            >
            {children}
        </motion.div>
    );
};

export default FadeEffect;
