import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation'
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {xStore} from "../utils/mobXstore";
import {localBaseURL} from "../utils/fetcher";

const PartnerSlider = () => {

    return (
        <div className='container py-big'>
            <div className="row mb-big">
                <div className="col-8">
                    <h2 className=''>NEWS</h2>
                </div>
                <div className="col-4 d-flex justify-content-end align-items-center more-link">
                    <Link to='/news' className='text-black'>
                        더보기
                        <img src="/img/arrow.svg" width={21} alt=""/>
                    </Link>
                </div>
            </div>
            <Swiper
                className='news-slides'
                modules={[Autoplay, Navigation]}
                slidesPerView={3}
                loop={true}
                navigation={true}
                spaceBetween={20}
                autoplay={{
                    delay: 1000000,
                    disableOnInteraction: false
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1
                    },
                    768: {
                        slidesPerView: 2
                    },
                    992: {
                        slidesPerView: 3
                    }
                }}
            >
                {xStore.news.map((element, id) => (
                    <SwiperSlide key={id}>
                        <Link className='card-item ellipsis' to={'/news/' + element.id}>
                            <div className="object-fit ar-16-9">
                                <img src={`${localBaseURL}/public/upload/news/${element.image}`} alt=''/>
                                <div className="gradient"/>
                            </div>
                            <h4 className='mt-lg-3 mt-2'>{element.title}</h4>
                            <h5 className='mt-lg-3 mt-2'>{element.subtitle}</h5>
                            <h6 className='mt-lg-2 mt-1 d-flex text-secondary'>{element.created_at}</h6>
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default observer(PartnerSlider);
