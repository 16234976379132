import React from 'react';
import {useForm} from "react-hook-form";
import {fetcher} from "../../../utils/fetcher";

const Scene5 = ({setScene, sceneData}) => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = (data) => {
        const form_data = {...sceneData, ...data}
        console.log(form_data)
        fetcher.post("/api/task/create", form_data,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        );
        setScene(p => p + 1)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className='mb-4'>연락처 정보</h4>
            <div className='row'>
                <div className='col-lg-4'>
                    <input
                        className='form-control col-4 mb-4'
                        defaultValue=""
                        placeholder='회사 이름을 입력해주세요'
                        {...register("companyName", {required: true})}
                    />
                    {errors.companyName && <span className='text-danger'>This field is required</span>}
                    <input
                        className='form-control col-4 mb-4'
                        defaultValue=""
                        placeholder='당신의 이름을 입력 해주세요'
                        {...register("contactName", {required: true})}
                    />
                    {errors.contactName && <span className='text-danger'>This field is required</span>}
                    <input
                        type='email'
                        className='form-control col-4 mb-4'
                        defaultValue=""
                        placeholder='이메일을 입력해주세요'
                        {...register("contactEmail", {required: true})}
                    />
                    {errors.contactEmail && <span className='text-danger'>This field is required</span>}
                    <input
                        type='tel'
                        className='form-control col-4'
                        defaultValue=""
                        placeholder='연락 가능한 전화번호를 입력해주세요'
                        {...register("contactPhone", {required: true})}
                    />
                    {errors.contactPhone && <span className='text-danger'>This field is required</span>}
                </div>
            </div>
            <br/>
            <button className='btn btn-dark mb-5 px-5' type='submit'>완료료</button>
        </form>
    );
};

export default Scene5;
