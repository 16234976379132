import React from 'react';
import ContactUs from "./ContactUs";
import FooterBottom from "./FooterBottom";

const Footer = () => {

    return (
        <>
            <footer className='py-big bg-pink'>
                <div className='container'>
                    <h2 className='mb-0'>Contact Us</h2>
                    <div className="row">
                        <div className="col-lg-6 mb-4">
                            <h5 className='mt-lg-5 mt-4'>
                                맞춤형 홈페이지제작, 앱개발, 쇼핑몰, E-Learning 시스템
                                <br />
                                구축 아웃소싱 관련 문의 저희 전문가가
                                <br />
                                언제라도 답변해 드립니다.
                            </h5>
                            <div className="divider mt-lg-5 mb-lg-4 my-3"></div>
                            <h4>MKIT LLC</h4>
                            <p>A. Union Building, Unesco Street 62, sukhbaatar District, Ulaanbaatar, Mongolia </p>
                            <h4>SOFT BRAINS LLC</h4>
                            <p>A. The Republic of Uzbekistan, Tashkent city, Mirobod region,Oybek street-22</p>
                            <h4>SOFTHUB GLOBAL</h4>
                            <p>
                                A. 경기도 파주시 소리천로 25, 919-16 (야당동, 유은타워 7차)
                            </p>
                            <p>
                                E. <a className='text-black' href = "mailto:jhlim@softhubglobal.com">jhlim@softhubglobal.com</a>
                            </p>
                            <p>
                                P. <a className='text-black' href = "tel:010-7720-3400">010-7720-3400</a>
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <ContactUs />
                        </div>
                    </div>
                </div>
            </footer>
            <FooterBottom />
        </>
    );
};

export default Footer;
