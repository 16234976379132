import axios from "axios";

export const fetcher = axios.create({
    // baseURL: 'http://192.168.0.11:80',
    baseURL: 'https://api.softhubglobal.com',

    // baseURL: 'http://khandid.co.kr:8080',
    // timeout: 1000,
    headers: {Authorization: 'Bearer WRTs3sdtse_dsn5y%&Y(Uy51IL$='}
});

export const localBaseURL = 'https://admin.softhubglobal.com'
