import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import './styles/index2.scss';
import './styles/typography.css';
import 'react-notifications/lib/notifications.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
