import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from 'swiper';
import 'swiper/css'
import {fetcher, localBaseURL} from "../utils/fetcher";

const PartnerSlider = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        fetcher.get('/api/partner/getAll')
            .then(res => {
                setData(res.data.data)
            })
    }, [])

    return (
        <div className='position-relative bg-pink z10 py-big'>
            <div className='container mb-big'>
                <h2>
                    <span>이미 많은</span> 클라이언트<span>와 함께하고 있습니다.</span>
                </h2>
            </div>
            <Swiper
                modules={[Autoplay]}
                slidesPerView={4}
                loop={true}
                className='partners-slider'
                spaceBetween={20}
                autoplay={{
                    delay: 1000,
                    disableOnInteraction: false
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 6
                    },
                }}
            >
                {[...data, ...data].map((element, id) => (
                    <SwiperSlide key={id} className='d-flex justify-content-center'>
                        {/*<Link to='/partners'>*/}
                        <img src={`${localBaseURL}/public/upload/partner/${element.image}`} alt={id}/>
                        {/*</Link>*/}
                    </SwiperSlide>
                ))}
            </Swiper></div>
    );
};

export default PartnerSlider;
