import React from 'react';
import {Link, useParams} from "react-router-dom";
import {xStore} from "../../utils/mobXstore";
import {observer} from "mobx-react-lite";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import parse from "html-react-parser";
import {localBaseURL} from "../../utils/fetcher";

const TechInfoItem = () => {
    const {id} = useParams()
    const faq = xStore.faq.filter(el => el.id.toString() === id)[0];
    return (
        <>
            <TopBar/>
            <div className="container py-big">
                <h1 className='col-lg-8 offset-lg-2 text-center mb-big'>{faq?.title}</h1>
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 parsed-content">
                        {parse(faq?.content.replaceAll('../../../', localBaseURL + '/') || '')}
                    </div>
                </div>
                {faq?.document && <div className=" col-lg-10 mt-lg-5 mt-3 d-flex justify-content-end">
                    <Link to={localBaseURL + '/public/upload/faqs/' + faq?.document}
                          target="_blank" download={true}>
                        <button className='btn btn-dark px-4'>
                            {/*<button className='btn btn-warning'>*/}
                            {faq?.document}
                        </button>
                    </Link>
                </div>}
            </div>
            <Footer/>
        </>
    );
};

export default observer(TechInfoItem);
