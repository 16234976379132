import React from 'react';
import {useForm} from "react-hook-form";

const Scene4 = ({setScene, sceneData, setSceneData}) => {
    const {register, handleSubmit} = useForm({
        defaultValues: {
            support: 'true'
        }
    });
    const onSubmit = (data) => {
        setSceneData({...sceneData, ...data, support: data.support === "true" ? 1 : 0})
        setScene(p => p + 1)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <h4 className='mb-4'>지출 가능 예산</h4>

            <div className="row mb-4">
                <div className="col-lg-3 position-relative">
                    <input
                        defaultValue=""
                        placeholder='0'
                        type='number'
                        className='form-control'
                        {...register("budget", {required: true})}
                    />
                    <div className="position-absolute fw-semibold" style={{top: 7, right: 40}}>원</div>
                </div>
            </div>
            <h4>시작, 종료일</h4>
            <p className='mb-4'>프로젝트 시작 그리고 개발완료하는 날짜입니다.</p>
            <div className="row mb-4">
                <div className="col-lg-3">
                    <input className='form-control' {...register('startDate', {required: true})} type="date"/>
                </div>
                <div className="col-lg-3">
                    <input className='form-control' {...register('endDate', {required: true})} type="date"/>
                </div>
            </div>

            <h4>지원사업여부</h4>
            <label htmlFor="s2class1">
                <input
                    {...register("support")}
                    type="radio"
                    value="true"
                    className='me-2 mb-3'
                    id="s2class1"
                />
                네, 정부지원사업 또는 연구과제 입니다.
            </label>
            <br/>
            <label htmlFor="s2class2">
                <input
                    {...register("support")}
                    type="radio"
                    value="false"
                    className='me-2 mb-3'
                    id="s2class2"
                />
                아닙니다.
            </label>
            <br/>
            <button type='submit' className='btn btn-dark mt-4 mb-5'>다음</button>
        </form>
    );
};

export default Scene4;
