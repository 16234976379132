import React from 'react';
import {localBaseURL} from "../../utils/fetcher";

const NewTechnologies = () => {
    return (
        <div className='row'>
            <div className="col-lg-6 mb-3 d-flex flex-column justify-content-center text-center">
                <div className="pt18 fw-bold text-secondary">
                    블록체인 기술 기반 Smart Contract / Coin 개발 <br/> <br/>
                    IOT 서비스 관련 기술 개발 <br/> <br/>
                    블록코딩 서비스 개발
                </div>
            </div>
            <div className="col-lg-6 mb-3">
                <img className='w-100' src={localBaseURL + "/public/filemanager/uploads/Rectangle%2013.png"} alt=""/>
            </div>
        </div>
    );
};

export default NewTechnologies;
