import {useEffect} from 'react';
import {useLocation} from "react-router-dom";

const ScrollOnPathChange = ({children}) => {
    const {pathname} = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0)
        window.scroll({
            top: 0,
            behavior: "smooth"
        })
    }, [pathname])
    return children
};

export default ScrollOnPathChange;
