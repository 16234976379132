import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Home from './Home'
import './styles/bootstrap.css'
import Project from "./pages/create/project";
import NewsPage from "./pages/news";
import NewsItem from "./pages/news/newsItem";
import BusinessPage from "./pages/business";
import AboutPage from "./pages/about";
import ScrollOnPathChange from "./components/ScrollOnPathChange";
import PortfolioItem from "./pages/portfolio";
import InfoItem from "./pages/business/infoItem";
import TeamPage from "./pages/team";
import Map from "./pages/contact/map";
import TechInfoPage from "./pages/techInfo";
import TechInfoItem from "./pages/techInfo/techInfoItem";
import {NotificationContainer} from "react-notifications";

const App = () => {
    return (
        <>
            <BrowserRouter>
                <NotificationContainer/>
                <ScrollOnPathChange>
                    <Routes>
                        <Route index element={<Home/>}/>
                        <Route path='create'>
                            <Route index element={<Navigate to='project'/>}/>
                            <Route path='project' element={<Project/>}/>
                            <Route path='outsourcing' element={<Project/>}/>
                        </Route>
                        <Route path='business' element={<BusinessPage/>}/>
                        <Route path='news' element={<NewsPage/>}/>
                        <Route path='about' element={<AboutPage/>}/>
                        <Route path='contact' element={<Map/>}/>
                        <Route path='techInfo' element={<TechInfoPage/>}/>
                        <Route path='techInfo/:id' element={<TechInfoItem/>}/>
                        <Route path='team' element={<TeamPage/>}/>
                        <Route path='news/:id' element={<NewsItem/>}/>
                        <Route path='business/:id' element={<InfoItem/>}/>
                        <Route path='portfolio/:id' element={<PortfolioItem/>}/>
                    </Routes>
                </ScrollOnPathChange>
            </BrowserRouter>
        </>
    )
};

export default App;
