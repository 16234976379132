import React from 'react';
import TopBar from "../../components/TopBar";
import FadeEffect from "../../components/FadeEffect";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { xStore } from "../../utils/mobXstore";
import { localBaseURL } from "../../utils/fetcher";
import BreadCrumb from '../../components/BreadCrumb';


const BusinessPage = () => {
    return (
        <>
            <TopBar />
            <div className="container mt-big mb-lg-5">
                <BreadCrumb page="Business" />
                <h2 className='mb-big'>사업분야</h2>
                <div className="row">
                    {xStore.info.map((element, id) => (
                        <div key={id} className="col-xl-4 col-lg-6 mb-5">
                            <FadeEffect>
                                <Link className='card-item' to={'/business/' + element.id}>
                                    <div style={{
                                        position: `relative`,
                                        background: `linear-gradient( rgba(0, 0,  0, 0.9), rgba(0, 0, 0, 0.1) ), url(${localBaseURL}/public/upload/info/${element.image}) `,
                                        backgroundSize: `cover`,
                                        backgroundRepeat: `no-repeat`
                                    }} className="object-fit ar-5-6 pt-4">
                                        {element.title.split('<br/>').map((el) => (
                                            <h5 key={id + el} className='ms-4 text-white'>
                                                {el}
                                            </h5>
                                        ))}
                                        <img src="/img/add2.png" style={{
                                            width: "20px",
                                            height: "20px"
                                        }} className='plus-button mt-4 ms-4 text-white' alt="" />
                                        <div className="gradient" />
                                    </div>
                                </Link>
                            </FadeEffect>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default observer(BusinessPage);
