import React, {useEffect, useState} from 'react';
import ScrollToTop from "react-scroll-to-top";

import OurBusiness from "./components/OurBusiness";
import Portfolio from "./components/Portfolio";
import Slider from "./components/Slider";
import Partners from "./components/Partners";
import Services from "./components/Services";
import Header from "./components/Header";
import Footer from "./components/Footer";

const App = () => {
    const [scrollY, setScrollY] = useState(0)

    const listener = () => setScrollY(window.scrollY)

    useEffect(() => {
        window.addEventListener('scroll', listener)
        return () => {
            window.removeEventListener('scroll', listener)
        }
    }, [])

    return (
        <>
            <Header/>
            <OurBusiness scrollY={scrollY}/>
            <Portfolio scrollY={scrollY}/>
            <Services scrollY={scrollY}/>
            <Partners/>
            <Slider/>
            <Footer/>
            <ScrollToTop smooth viewBox='0 0 0 0' />
        </>
    )
};

export default App;
