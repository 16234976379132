import React from 'react';
import {localBaseURL} from "../../utils/fetcher";

const ELearning = () => {
    return (
        <div className='row'>
            <div className="col-lg-6 mb-3 d-flex flex-column justify-content-center text-center">
                <div className="pt18 fw-bold text-secondary">
                    학습 원고 자동 업로드 <br/>
                    온라인 학습 <br/>
                    모의 테스트 <br/>
                    동영상 강의 VOD/ Live-On 스트리밍 서비스 <br/>
                    E-Learnig 서비스 제공을 위한 <br/>
                    시스템 설계/디자인/Front/Back-End 개발을 수행 했습니다. <br/>
                    <br/>
                    이투스, 뤼이드, 메스레볼루션, 리치프렙, 유니월드 등 <br/>
                    서비스 개발 및 유지보수 수행
                </div>
            </div>
            <div className="col-lg-6 mb-3">
                <img className='w-100' src={localBaseURL + "/public/filemanager/uploads/e%20learning.png"} alt=""/>
            </div>
            <div className="col-lg-6 mb-3">
                <img className='w-100' src={localBaseURL + "/public/filemanager/uploads/e2.png"} alt=""/>
            </div>
            <div className="col-lg-6 mb-3">
                <img className='w-100' src={localBaseURL + "/public/filemanager/uploads/e3.png"} alt=""/>
            </div>
        </div>
    );
};

export default ELearning;
